import { createContext, FC } from 'react';
import { ContextProps, ProviderProps, State } from './Store';
import { useStoreReducer } from './useStoreReducer';

const initialState: State = {
  previousPath: '',
  isAuthenticated: false,
  authError: null,
};

const SESSION_AUTH_KEY = process.env.REACT_APP_SESSION_AUTH_KEY || '';

export const StoreContext = createContext<ContextProps>({
  state: initialState,
  dispatch: () => {},
});

export const StoreProvider: FC<ProviderProps> = ({ children }) => {
  const [originalState, dispatch] = useStoreReducer(initialState);

  const state = {
    ...originalState,
    isAuthenticated: localStorage.getItem(SESSION_AUTH_KEY) === 'true',
  };

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};
