import theaterBuehne from '../assets/images/theater-kr-mg/rikefolio-theater-kr-mg-buehne.jpg';
import theaterEnsembleDesktop from '../assets/images/theater-kr-mg/rikefolio-theater-kr-mg-ensemble-desktop.jpg';
import theaterKalenderMobil from '../assets/images/theater-kr-mg/rikefolio-theater-kr-mg-kalender-mobil.jpg';
import theaterKalender from '../assets/images/theater-kr-mg/rikefolio-theater-kr-mg-kalender.png';
import theaterStartseite from '../assets/images/theater-kr-mg/rikefolio-theater-kr-mg-startseite.png';
import theaterStueck from '../assets/images/theater-kr-mg/rikefolio-theater-kr-mg-stueck.png';

import naloxonBuehne from '../assets/images/naloxon/rikefolio-naloxon-buehne.jpg';
import naloxonDesktop from '../assets/images/naloxon/rikefolio-naloxon-desktop.jpg';
import naloxonStartseite from '../assets/images/naloxon/rikefolio-naloxon-startseite.jpg';
import naloxonTablet from '../assets/images/naloxon/rikefolio-naloxon-tablet.jpg';

import brandportalBuehne from '../assets/images/brandportal/rikefolio-brandportal-buehne.jpg';
import brandportalDesktop from '../assets/images/brandportal/rikefolio-brandportal-desktop.jpg';
import brandportalStartseite from '../assets/images/brandportal/rikefolio-brandportal-startseite.jpg';
import brandportalTablet from '../assets/images/brandportal/rikefolio-brandportal-tablet.jpg';

import michelsenBuehne from '../assets/images/l-w-c-michelsen/rikefolio-lwc-michelsen-buehne.jpg';
import michelsenNewsletter from '../assets/images/l-w-c-michelsen/rikefolio-lwc-michelsen.jpg';

import twitchBuehne from '../assets/images/twitch/rikefolio-twitch-buehne.jpg';
import twitchFusselchu from '../assets/images/twitch/rikefolio-twitch-fusselchu.jpg';
import twitchHumie from '../assets/images/twitch/rikefolio-twitch-humie.jpg';
import twitchKimuh from '../assets/images/twitch/rikefolio-twitch-kimuh.jpg';
import twitchMoonboon from '../assets/images/twitch/rikefolio-twitch-moonboon.jpg';

import montagmorgensBuehne from '../assets/images/montagmorgens/rikefolio-montagmorgens-buehne.jpg';
import moScreen from '../assets/images/montagmorgens/rikefolio-montagmorgens.png';
import moKontakt from '../assets/lotties/mo-kontakt.json';
import moStartseite from '../assets/lotties/mo-startseite.json';

import lottieHoverBlack from '../assets/lotties/rikefolio-button-black.json';
import lottieHoverGrey from '../assets/lotties/rikefolio-button-grey.json';
import lottieHoverWhite from '../assets/lotties/rikefolio-button-white.json';

import { Project } from '../types/Project';

export const getFeaturedProjects = (featuredProjects: string[]) => {
  return projects.filter(
    (project) => project.id === featuredProjects[0] || project.id === featuredProjects[1]
  );
};

export const projects: Project[] = [
  {
    id: 'theater-krefeld',
    title: 'Das Theater Krefeld Mönchengladbach im neuen Gewand.',
    category: 'Website',
    description: [
      'Vielfalt. Übersicht. Orientierung.',
      'Ein Projekt, welches zusammen mit dem Theater Krefeld Mönchengladbach entstanden ist. Zusammen mit meiner Kollegin Katharina Fedder habe ich das Konzept der Seite entwickelt und anschließend das User Experience Design und User Interface auf Basis des bestehenden Spielzeitheftes erstellt und für die Webanwendung weiterentwickelt.',
      'Die Herausforderung hierbei war es, die Bedürfnisse der verschiedenen Zielgruppen zu erfüllen. Durch den besonderen Slider in der Bühne wird jede Theater Sparte separat präsentiert und erhält ihren Auftritt. Besonders Spaß gemacht haben die Seiten Kalender, der Spielplan und die Seiten der Inszenierungen. Für den Kunden war die Responsibilität besonders wichtig, daher sind die einzelnen Seiten zuerst in der Mobilen Variante konzipiert worden.',
      'Für Bilder und Inhalte, die auf der tatsächlichen Seite zu finden, sind wir nicht verantwortlich.',
    ],
    descriptionColor: '#FFFFFF',
    image: theaterBuehne,
    color: '#5E0B0B',
    url: '/projekt/theater-krefeld-moenchengladbach',
    externalUrl: 'https://theater-kr-mg.de/',
    externalLinkLottie: lottieHoverWhite,
    featuredProjects: ['naloxon', 'saueressig'],
    sections: [
      {
        title: 'Startseite',
        titleColor: '#FFFFFF19',
        image: theaterStartseite,
      },
      {
        title: 'Kalender',
        titleColor: '#FFFFFF19',
        image: theaterKalenderMobil,
      },
      {
        title: ' ',
        titleColor: '#FFFFFF19',
        image: theaterKalender,
      },
      {
        title: 'Inszenierung',
        titleColor: '#FFFFFF19',
        image: theaterStueck,
      },
      {
        title: ' ',
        titleColor: '#FFFFFF19',
        image: theaterEnsembleDesktop,
      },
    ],
  },
  {
    id: 'naloxon',
    title: 'Naloxon Training - Erste Hilfe für den Drogennotfall',
    category: 'Website',
    description: [
      'Deutschlands erste Trainingsplattform für den Drogennotfall.',
      'Im Auftrag der Deutschen Aidshilfe e.V. habe ich zusammen mit meiner aktuellen Agentur Deutschlands erste Trainingsplattform für die Erste Hilfe im Drogennotfall entwickelt. Der Fokus hierbei lag besonders auf der mobilen Darstellung der einzelnen Seiten. Wichtig war es, dass die Nutzer*innen schnell an Informationen gelangt und sofort weiß was in einem Drogennotfall zu tun ist. Auf der Plattform wird das Wissen in drei kurzen Aufklärungsfilme zusammengefasst, im Anschluss kann der Nutzer einen Online-Test durchführen. Wenn dieser Test bestanden ist, bekommt der Nutzer ein Zertifikat welches der Hausarzt oder Hausärztin bestätigen kann. Die Seite wurde als progressive Web App entwickelt und kann so auch im Offline-Modus geöffnet werden.',
    ],
    descriptionColor: '#2C4252',
    image: naloxonBuehne,
    color: '#C1E4F8',
    url: '/projekt/naloxon-training',
    externalUrl: 'https://www.naloxontraining.de/',
    externalLinkLottie: lottieHoverGrey,
    featuredProjects: ['saueressig', 'montagmorgens'],
    sections: [
      {
        title: 'Startseite',
        titleColor: '#A2D0EB',
        image: naloxonStartseite,
      },
      {
        title: 'Trainingsvideos',
        titleColor: '#A2D0EB',
        image: naloxonTablet,
      },
      {
        title: 'Online Training',
        titleColor: '#A2D0EB',
        image: naloxonDesktop,
      },
    ],
  },
  {
    id: 'saueressig',
    title: 'Brandportal für Marke und Mitarbeiter',
    category: 'Website',
    description: [
      'Für die Marke Saueressig entwickelte mein derzeitiger Arbeitgeber ein Corporate Design. Um das Corporate Design auch im Unternehmen und an den verschiedenen internationalen Standorten zu festigen, kam die Idee auf, ein internes Brandportal zu erstellen. Der Fokus lag auf der einfachen Navigation, der Übersichtlichkeit und auf der Desktop-Ansicht. Daher haben wir uns hier für eine feststehende Navigation entschieden, die den verschiedenen Untermenüpunkten gerecht wird. Die Mitarbeiter*innen der Marke Saueressig können sich auf dieser Seite Präsentationen, Logos, Imagebroschüren, Signaturen und vieles mehr herunterladen.',
    ],
    descriptionColor: '#ffffff',
    image: brandportalBuehne,
    color: '#0055A6',
    url: '/projekt/saueressig-brandportal',
    externalUrl: '',
    externalLinkLottie: null,
    featuredProjects: ['montagmorgens', 'newsletter'],
    sections: [
      {
        title: 'Startseite',
        titleColor: '#1F69B1',
        image: brandportalStartseite,
      },
      {
        title: 'Logo',
        titleColor: '#1F69B1',
        image: brandportalDesktop,
      },
      {
        title: 'Vorlagen',
        titleColor: '#1F69B1',
        image: brandportalTablet,
      },
    ],
  },
  {
    id: 'montagmorgens',
    title: 'Die Kreativabteilung',
    category: 'Website',
    description: [
      'Ein neuer Website auftritt für die Kreativagentur MONTAGMORGENS GmbH.',
      'Das Corporate Design wurde durch die neue Website weiterentwickelt. Es war wichtig einen frischen und offenen Look zu kreieren. Wir haben mit viel Weißraum gearbeitet, um das Orange hervorzuheben. Mithilfe von illustrativen Elementen ist an mancher Stelle auch ein Montagmorgen zu sehen.',
      'Das User Interface und die User Experience sollten klar definiert sein, der Nutzer soll sich schnell zurechtfinden und die wichtigsten Punkte finden. Es gibt wenige Menüpunkte, die klar definiert sind, durch ein mitscrollendes Hamburgermenü kann der Nutzer jederzeit die Seite wechseln. Einzelne Landingpages sind an gezielter Stelle hinterlegt und bietet dem Nutzer weiteren Content. Die einzelnen Referenzen sind auf der Seite als Baukastensystem hinterlegt, durch verschiedene Bilder, Mockups und Screenshot ist jede Referenz individuell angelegt und erhielt die passende Farbe zum Projekt.',
      'Die animierten Illustrationen sind das Herz der Website. Diese sind zuerst in Adobe Illustrator entstanden. Im Anschluss habe ich diese in After Effects animiert und jedes einzelne grafische Element besitzt seinen eigenen Keyframe. Die Animationen wurden auf der Website direkt im Code eingebunden, diese Art von Einbindung verbraucht kaum Speicher, ist effizient und schnell.',
      'Schau dir gerne mal die 404 Seite oder die Kontaktseite an.',
    ],
    descriptionColor: '#000000',
    image: montagmorgensBuehne,
    color: '#ffffff',
    url: '/projekt/montagmorgens',
    externalUrl: 'https://www.montagmorgens.com/',
    externalLinkLottie: lottieHoverBlack,
    featuredProjects: ['newsletter', 'illustration'],
    sections: [
      {
        title: '',
        titleColor: '',
        lottie: moStartseite,
      },
      {
        title: 'Startseite',
        titleColor: '#EEEEEE',
        image: moScreen,
      },
      {
        title: '',
        titleColor: '',
        lottie: moKontakt,
        imageWidth: '50%',
      },
    ],
  },
  {
    id: 'newsletter',
    title: 'Eine Manufaktur der Köstlichkeiten - L.W.C. Michelsen',
    category: 'Newsletter',
    description: [
      'Ein Newsletter für Feinschmecker.',
      'Gerne möchte ich hier einen Newsletter vorstellen, den ich im letzten Jahr konzipiert und entworfen habe. Der Kunde L.W.C. Michelsen verkauft hochwertige Köstlichkeiten an Endverbraucher und Unternehmen. Das Unternehmen betreibt viel Marketing, es fehlte lediglich nur noch ein regelmäßiger Newsletter im Corporate Look.',
      'Ich habe das Projekt von der Akquise, über die Angebotserstellung, bis hin zum fertigen Produkt betreut und geleitet. Der Kunde hat von uns mithilfe von CleverReach und deren Drag-and-drop Editor ein Newsletter erhalten, mit dem er alle seine Bedürfnisse decken kann und seine Zielgruppenspezifischen Newsletter versenden kann.',
    ],
    descriptionColor: '#39283D',
    image: michelsenBuehne,
    color: '#C9C1D8',
    url: '/projekt/l-w-c-michelsen',
    externalUrl: '',
    externalLinkLottie: null,
    featuredProjects: ['theater-krefeld', 'illustration'],
    sections: [
      {
        title: 'Newsletter',
        titleColor: '#B9B0CB',
        image: michelsenNewsletter,
      },
    ],
  },
  {
    id: 'illustration',
    title: 'Emote Illustrationen für Twitch',
    category: 'Illustration',
    description: [
      'Emotes sind eines den wichtigsten Kommunikationsmitteln auf der Plattform Twitch, um Emotionen, Ausdrücke und seine geschriebenen Worte verständlicher für den Streamer und den Nutzern darzustellen.',
      'Einiger meiner Illustrationen, die ich für verschiedene Content-Creator auf Twitch erstellt habe, findest du hier.',
    ],
    descriptionColor: '#0035DC',
    image: twitchBuehne,
    color: '#FFFBF5',
    url: '/projekt/illustration',
    externalUrl: '',
    externalLinkLottie: null,
    featuredProjects: ['theater-krefeld', 'naloxon'],
    sections: [
      {
        title: '',
        titleColor: '',
        image: twitchHumie,
      },
      {
        title: '',
        titleColor: '',
        image: twitchKimuh,
      },
      {
        title: '',
        titleColor: '',
        image: twitchFusselchu,
      },
      {
        title: '',
        titleColor: '',
        image: twitchMoonboon,
      },
    ],
  },
];
