import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Spacer } from '../Spacer/Spacer';
import { Text } from '../Text/Text';
import styles from './Footer.module.scss';

export const Footer: FC = () => {
  return (
    <footer className={styles.footer} data-testid="footer">
      <Container>
        <Spacer size="md" />
        <Row>
          <Col>
            <Text className={styles.copyright}>© 2022 Friederike Zanders</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.infoContainer}>
              <Text>friederike.zanders@gmail.com</Text>
              <Text className={styles.divider}>|</Text>
              <Text>0176 84982561</Text>
              <Text className={styles.divider}>|</Text>
              <Text>Willicher Straße 53, 47918 Tönisvorst</Text>
            </div>
          </Col>
        </Row>
        <Spacer size="md" />
      </Container>
    </footer>
  );
};
