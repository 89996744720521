import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app/App';
import './assets/styles/index.scss';
import { ScrollReset } from './components/ScrollReset/ScrollReset';
import { StoreProvider } from './store/StoreContext';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <StoreProvider>
        <ScrollReset>
          <App />
        </ScrollReset>
      </StoreProvider>
    </BrowserRouter>
  </StrictMode>
);
