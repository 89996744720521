import classNames from 'classnames';
import { FC, HTMLProps } from 'react';
import styles from './Text.module.scss';

export type Variant = 'hero' | 'verticalHero' | 'subhero' | 'title' | 'text' | 'paragraph';
export type Font = 'judson' | 'lato';
export type Color = 'blue' | 'black' | 'white' | 'gray' | 'error';

export interface TextProps extends HTMLProps<HTMLDivElement> {
  variant?: Variant;
  font?: Font;
  color?: Color | string;
  bold?: boolean;
  testId?: string;
}

export const Text: FC<TextProps> = (props) => {
  const { children, className, style, variant, font, color, bold, testId } = props;
  const fontStyle = styles[`font-${font}`];
  const boldStyle = bold && styles.bold;
  const classes = [fontStyle, boldStyle, className];

  let _style = { ...style };

  if (color) {
    if (color.startsWith('#')) {
      _style = {
        ...style,
        color,
      }
    } else {
      classes.push(styles[`color-${color}`]);
    }
  }

  switch (variant) {
    case 'hero':
      return (
        <h1 className={classNames(styles.hero, classes)} style={_style} data-testid={testId}>
          {children}
        </h1>
      );

    case 'verticalHero':
      return (
        <div
          className={classNames(styles.verticalHero, classes)}
          style={style}
          data-testid={testId}
        >
          {children}
        </div>
      );

    case 'subhero':
      return (
        <p className={classNames(styles.subhero, classes)} style={_style} data-testid={testId}>
          {children}
        </p>
      );

    case 'title':
      return (
        <h2 className={classNames(styles.title, classes)} style={_style} data-testid={testId}>
          {children}
        </h2>
      );

    case 'paragraph':
      return (
        <p className={classNames(styles.text, classes)} style={_style} data-testid={testId}>
          {children}
        </p>
      );

    default:
      return (
        <div className={classNames(styles.text, classes)} style={_style} data-testid={testId}>
          {children}
        </div>
      );
  }
};
