import { FC, HTMLProps } from 'react';
import { Footer } from '../Footer/Footer';
import { NavBar } from '../NavBar/NavBar';
import styles from './Page.module.scss';

type PageProps = HTMLProps<HTMLDivElement>;

export const Page: FC<PageProps> = (props) => {
  return (
    <div>
      <NavBar />
      <div className={styles.content}>{props.children}</div>
      <Footer />
    </div>
  );
};
