import { useReducer } from 'react';
import { Action, AuthError, State } from './Store';

const SECRET = process.env.REACT_APP_SECRET;
const SESSION_AUTH_KEY = process.env.REACT_APP_SESSION_AUTH_KEY || '';

export const useStoreReducer = (initialState: State) => {
  const reducer = (state: State, action: Action) => {
    switch (action.type) {
      case 'SET_PATHNAME':
        return {
          ...state,
          previousPath: action.payload,
        };

      case 'LOGIN':
        const pwMatches = action.payload === SECRET;
        let authError: AuthError;

        localStorage.setItem(SESSION_AUTH_KEY, `${pwMatches}`);

        if (!action.payload) authError = 'PW_EMPTY';
        else if (!pwMatches) authError = 'PW_INVALID';

        return {
          ...state,
          isAuthenticated: pwMatches,
          authError,
        };

      case 'CLEAR':
        localStorage.clear();
        return state;

      default:
        return state;
    }
  };

  return useReducer(reducer, initialState);
};
