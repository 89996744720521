import { FC, HTMLProps } from 'react';
import { Project } from '../../../types/Project';
import { Link } from '../../Link/Link';
import { ScrollReveal } from '../../ScrollReveal/ScrollReveal';
import { Spacer } from '../../Spacer/Spacer';
import { Text } from '../../Text/Text';
import styles from './ProjectListItem.module.scss';

interface ProjectListItemProps extends HTMLProps<HTMLDivElement> {
  project: Project;
}

export const ProjectListItem: FC<ProjectListItemProps> = ({ project }) => {
  return (
    <ScrollReveal>
      <div className={styles.listItemImgContainer}>
        <Link to={project.url}>
          <img className={styles.listItemImg} src={project.image} alt={project.title} />
        </Link>
      </div>
      <Spacer size="sm" />
      <Text className={styles.categoryText} color="gray" bold>
        {project.category.toUpperCase()}
      </Text>
      <Spacer size="xs" />
      <Link to={project.url}>
        <Text variant="title">{project.title}</Text>
      </Link>
      <Spacer size="xs" />
      <Link to={project.url} animated iconRight>
        Zum Projekt
      </Link>
    </ScrollReveal>
  );
};
