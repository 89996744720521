import { FC, HTMLProps } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text } from '../Text/Text';
import styles from './Section.module.scss';

interface SectionProps extends HTMLProps<HTMLDivElement> {
  title?: string;
  titleColor?: string;
}

export const Section: FC<SectionProps> = (props) => {
  const { children, title, titleColor, className } = props;

  return (
    <Row className={className} data-testid="section">
      {title && (
        <Col className={styles.titleCol} xs={1}>
          <Text style={{ color: titleColor }} variant="verticalHero">
            {title}
          </Text>
        </Col>
      )}
      <Col xs={12} lg={{ span: 10, offset: title ? 0 : 1 }}>
        {children}
      </Col>
    </Row>
  );
};
