import { FC } from 'react';

type SpacerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

interface SpacerProps {
  size: SpacerSize;
  horizontal?: boolean;
}

export const Spacer: FC<SpacerProps> = (props) => {
  const { size, horizontal } = props;

  const getSize = () => {
    switch (size) {
      case 'xs':
        return '1em';
      case 'sm':
        return '2em';
      case 'md':
        return '3em';
      case 'lg':
        return '4em';
      case 'xl':
        return '5em';
      case 'xxl':
        return '10em';
      default:
        return '1em';
    }
  };

  return (
    <div
      data-testid="spacer"
      style={{
        marginTop: horizontal ? 0 : getSize(),
        marginLeft: horizontal ? getSize() : 0,
      }}
    />
  );
};
