import { FC, HTMLProps, useEffect, useRef } from 'react';
import scrollReveal from 'scrollreveal';

export const ScrollReveal: FC<HTMLProps<HTMLDivElement>> = (props) => {
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ref = sectionRef.current;

    if (ref) {
      scrollReveal().reveal(ref, {
        distance: '100%',
        origin: 'bottom',
        duration: 1500,
        cleanup: true,
        viewOffset: {
          bottom: 300,
        },
      });
    }
  }, []);

  return <div ref={sectionRef}>{props.children}</div>;
};
