import { FC, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import artwork from '../../assets/images/artwork.svg';
import { Button } from '../../components/Button/Button';
import { Spacer } from '../../components/Spacer/Spacer';
import { Text } from '../../components/Text/Text';
import { useStore } from '../../store/useStore';
import styles from './Login.module.scss';

export const Login: FC = () => {
  const { state, dispatch } = useStore();
  const [pw, setPw] = useState<string>('');

  if (state.isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container className={styles.container}>
      <Spacer size="md" />
      <Row>
        <Col className="d-flex" xs="12">
          <img className={styles.artworkImg} src={artwork} alt="artwork" />
        </Col>
      </Row>
      <Spacer size="md" />
      <Row>
        <Col>
          <Form
            onSubmit={(e) => {
              e.preventDefault();

              dispatch({
                type: 'LOGIN',
                payload: pw,
              });
            }}
          >
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Geben Sie bitte das Passwort ein</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                autoComplete="password"
                onInput={(e) => {
                  setPw(e.currentTarget.value);
                }}
              />
            </Form.Group>
            {state.authError && <Spacer size="sm" />}
            {state.authError === 'PW_EMPTY' && (
              <Text color="error">Bitte geben Sie das Passwort ein.</Text>
            )}
            {state.authError === 'PW_INVALID' && (
              <Text color="error">Das eingegebene Passwort ist falsch.</Text>
            )}
            <Spacer size="sm" />
            <Button>Absenden</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
