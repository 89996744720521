import { FC, HTMLProps, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollReset: FC<HTMLProps<HTMLDivElement>> = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{props.children}</>;
};
