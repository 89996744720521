import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Project } from '../../types/Project';
import styles from './ProjectList.module.scss';
import { ProjectListItem } from './ProjectListItem/ProjectListItem';

interface ProjectListProps {
  projects: Project[];
}

export const ProjectList: FC<ProjectListProps> = ({ projects }) => {
  return (
    <Row data-testid="project-list">
      {projects.map((project, index) => (
        <Col key={project.title + index} className={styles.projectCol} xs={12} lg={6}>
          <ProjectListItem project={project} />
        </Col>
      ))}
    </Row>
  );
};
