import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import artwork from '../../assets/images/artwork.svg';
import { Button } from '../../components/Button/Button';
import { Spacer } from '../../components/Spacer/Spacer';
import { Text } from '../../components/Text/Text';
import styles from './404.module.scss';

export const Page404: FC = () => {
  const navigate = useNavigate();

  return (
    <Container className={styles.container}>
      <Spacer size="md" />
      <Row>
        <Col className="d-flex" xs="12">
          <img className={styles.artworkImg} src={artwork} alt="artwork" />
        </Col>
      </Row>
      <Spacer size="md" />
      <Row>
        <Col className={styles.textContainer}>
          <Text variant="hero">404</Text>
          <Text>Die Seite konnte leider nicht gefunden werden.</Text>
          <Spacer size="sm" />
          <Button onClick={() => navigate(-1)}>Zurück</Button>
        </Col>
      </Row>
    </Container>
  );
};
