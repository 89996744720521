import { FC, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/rike-logo.svg';
import { Button } from '../Button/Button';
import styles from './NavBar.module.scss';

const navLinks = [
  { id: 'about', title: 'About' },
  { id: 'projekt', title: 'Projekte' },
  { id: 'kontakt', title: 'Kontakt' },
];

export const NavBar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      className={styles.navbar}
      expand="md"
      fixed="top"
      expanded={expanded}
      data-testid="navbar"
    >
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="rike-logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          className={styles.navbarToggle}
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav>
            {navLinks.map(({ id, title }) => {
              const href = `/#${id}`;

              return (
                <div key={title}>
                  <Nav.Link
                    className="ps-md-5"
                    onClick={() => {
                      const isHome = location.pathname === '/';
                      setExpanded(false);

                      navigate(href, {
                        state: {
                          scrollId: id,
                          direct: !isHome,
                        },
                        replace: isHome,
                      });
                    }}
                  >
                    <Button>{title}</Button>
                  </Nav.Link>
                </div>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
