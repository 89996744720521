import lottie, { AnimationItem } from 'lottie-web';
import { FC, HTMLProps, useEffect, useRef } from 'react';

interface LottieProps extends HTMLProps<HTMLDivElement> {
  file: any;
  play?: boolean;
  loop?: boolean;
}

export const Lottie: FC<LottieProps> = (props) => {
  const { file, play, loop = false, className, style, onClick } = props;
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<AnimationItem>();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        animationData: file,
        rendererSettings: {
          preserveAspectRatio: 'none',
        },
        container: element.current,
        autoplay: false,
        loop,
      });
    }

    return () => {
      lottieInstance.current?.destroy();
    };
  }, [file, loop]);

  useEffect(() => {
    if (play) lottieInstance.current?.play();
    else lottieInstance.current?.stop();
  }, [play]);

  return (
    <div className={className} style={style} ref={element} onClick={onClick} data-testid="lottie" />
  );
};
