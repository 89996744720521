import { FC, HTMLProps } from 'react';
import { Navigate } from 'react-router-dom';
import { useStore } from '../../store/useStore';

type ProtectedRouteProps = HTMLProps<HTMLDivElement>;

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { state } = useStore();

  if (!state.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};
