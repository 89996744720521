import classNames from 'classnames';
import { FC, HTMLProps, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconArrow } from '../../assets/images/arrow-right.svg';
import defaultLottieFile from '../../assets/lotties/rikefolio-button-blue.json';
import { Lottie } from '../Lottie/Lottie';
import styles from './Link.module.scss';

interface LinkProps extends HTMLProps<HTMLLinkElement> {
  to?: string;
  color?: string;
  animated?: boolean;
  underline?: boolean;
  iconRight?: boolean;
  iconLeft?: boolean;
  back?: boolean;
  lottieFile?: any;
}

export const Link: FC<LinkProps> = (props) => {
  const { children, to, color, animated, underline, iconRight, iconLeft, back, lottieFile } = props;

  const [hover, setHover] = useState<boolean>(false);
  const element = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const external = to?.startsWith('http') || to?.startsWith('mailto');

  const classes = classNames(styles.link, underline && styles.underline);

  return (
    <div
      className={styles.linkContainer}
      data-testid="link"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      {animated && (
        <Lottie className={styles.lottie} file={lottieFile || defaultLottieFile} play={hover} />
      )}
      {iconLeft && <IconArrow className={styles.iconLeft} fill={color || '#0035DC'} />}
      <a
        href={to}
        target={external ? '_blank' : ''}
        rel="noreferrer"
        onClick={(e) => {
          if (!external) {
            e.preventDefault();

            if (back) {
              return navigate(-1);
            }

            if (to) {
              return navigate(to);
            }
          }
        }}
      >
        <div ref={element} className={classes} style={{ color }}>
          {children}
        </div>
      </a>
      {iconRight && (
        <IconArrow
          className={external ? styles.iconExternal : styles.iconRight}
          fill={color || '#0035DC'}
        />
      )}
    </div>
  );
};
