import classNames from 'classnames';
import { FC, HTMLProps, useRef, useState } from 'react';
import lottieButton from '../../assets/lotties/rikefolio-button-blue.json';
import { Lottie } from '../Lottie/Lottie';
import styles from './Button.module.scss';

interface ButtonProps extends HTMLProps<HTMLButtonElement> {}

export const Button: FC<ButtonProps> = (props) => {
  const { children, className, onClick } = props;

  const [hover, setHover] = useState<boolean>(false);
  const element = useRef<HTMLDivElement>(null);
  const classes = classNames(styles.button, className);

  return (
    <button
      className={classes}
      data-testid="button"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={onClick}
    >
      <Lottie className={styles.lottie} file={lottieButton} play={hover} />
      <div ref={element}>{children}</div>
    </button>
  );
};
