import classNames from 'classnames';
import { FC, HTMLProps, useRef, useState } from 'react';
import { Lottie } from '../Lottie';
import styles from './LottieHover.module.scss';

interface LottieHoverProps extends HTMLProps<HTMLDivElement> {
  file: any;
  loop?: boolean;
}

export const LottieHover: FC<LottieHoverProps> = (props) => {
  const { file, loop, width, height, size, className } = props;

  const [hover, setHover] = useState<boolean>(false);
  const element = useRef<HTMLDivElement>(null);

  const classes = classNames(styles.container, className);

  return (
    <div
      ref={element}
      className={classes}
      data-testid="lottie-hover"
      style={{
        width: size || width,
        height: size || height,
      }}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
    >
      <Lottie file={file} play={hover} loop={loop} />
    </div>
  );
};
