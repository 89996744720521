import { FC, RefObject, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import artwork from '../../assets/images/artwork.svg';
import lottieArrow from '../../assets/lotties/rikefolio-button-arrow.json';
import lottieLinkedin from '../../assets/lotties/rikefolio-button-social-linkedin.json';
import lottieXing from '../../assets/lotties/rikefolio-button-social-xing.json';
import { Link } from '../../components/Link/Link';
import { Lottie } from '../../components/Lottie/Lottie';
import { LottieHover } from '../../components/Lottie/LottieHover/LottieHover';
import { Page } from '../../components/Page/Page';
import { ProjectList } from '../../components/ProjectList/ProjectList';
import { Section } from '../../components/Section/Section';
import { Spacer } from '../../components/Spacer/Spacer';
import { Text } from '../../components/Text/Text';
import { projects } from '../../constants/projects';
import { useStore } from '../../store/useStore';
import styles from './Home.module.scss';

interface locationState {
  scrollId: string;
  direct: boolean;
}

export const Home: FC = () => {
  const { dispatch } = useStore();
  const projectRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navState = location.state as locationState;

  const scrollTo = (ref?: RefObject<HTMLDivElement>, direct?: boolean) => {
    let scrollY = 0;

    if (ref?.current) {
      const elementY = ref.current.getBoundingClientRect().top;
      const pageOffset = window.scrollY;
      const navOffset = 90;

      scrollY = elementY + pageOffset - navOffset;
    }

    window.scrollTo({ top: scrollY, behavior: direct ? 'auto' : 'smooth' });
  };

  useEffect(() => {
    switch (navState?.scrollId) {
      case 'about':
        return scrollTo();

      case 'projekt':
        return scrollTo(projectRef, navState.direct);

      case 'kontakt':
        return scrollTo(contactRef, navState.direct);
    }
  }, [navState]);

  return (
    <Page>
      <Container>
        <div className={styles.header}>
          <Spacer size="sm" />
          <Row>
            <Col className="d-flex" xs="12">
              <img className={styles.artworkImg} src={artwork} alt="artwork" />
            </Col>
          </Row>
          <Spacer size="lg" />
          <Section title="About">
            <Text className={styles.heroText} variant="hero" testId="home-hero">
              Hey, ich bin Rike und UI/UX Designer.
            </Text>
            <Spacer size="xs" />
            <Text variant="subhero">
              Meine Leidenschaft ist es, nutzerorientierte Lösungen zu gestalten.
            </Text>
          </Section>
          <Lottie
            className={styles.lottieArrow}
            file={lottieArrow}
            onClick={() => {
              dispatch({
                type: 'SET_PATHNAME',
                payload: '#projekte',
              });
            }}
            loop
            play
          />
          <Spacer size="sm" />
        </div>
        <Spacer size="xxl" />
        <Section>
          <div ref={projectRef}>
            <Text variant="title">Dies sind meine Lieblingsprojekte.</Text>
            <div className="my-4">
              <div className={styles.divider} />
            </div>
            <Text variant="paragraph" color="blue">
              Auf meiner Website zeige ich eine Auswahl an Arbeiten, die bei meiner Arbeit als User Interface und User Experience Designer entstanden sind. Neben dieser Arbeit liebe ich es auch zu Illustrieren. Einige meiner Illustrationen, die ich für verschiedene Content Creator erstellt habe, finden sich ebenfalls in meiner Projektliste.
            </Text>
          </div>
        </Section>
        <Spacer size="lg" />
        <Section title="Projekte">
          <ProjectList projects={projects} />
        </Section>
        <Spacer size="xxl" />
        <Section title="Kontakt">
          <div className={styles.contactSection} ref={contactRef}>
            <Text variant="hero">Ich freue mich über jede Nachricht.</Text>
            <Spacer size="xs" />
            <Link to="mailto:friederike.zanders@gmail.com" animated underline>
              friederike.zanders@gmail.com
            </Link>
            <Spacer size="md" />
            <div className={styles.socialContainer}>
              <Link to="https://www.linkedin.com/in/friederike-zanders-7652181bb/">
                <LottieHover className={styles.lottieSocial} file={lottieLinkedin} loop />
              </Link>
              <Spacer size="sm" horizontal />
              <Link to="https://www.xing.com/profile/Friederike_Zanders/cv">
                <LottieHover className={styles.lottieSocial} file={lottieXing} loop />
              </Link>
            </div>
            <Spacer size="xxl" />
            <div className={styles.quoteContainer}>
              <Text font="judson" color="blue">
                “A user interface is like a joke. If you have to explain it, it’s not that good.”
              </Text>
              <Spacer size="xs" />
              <Text className={styles.authorName} color="gray" bold>
                Martin LeBlanc
              </Text>
            </div>
          </div>
        </Section>
        <Spacer size="xxl" />
      </Container>
    </Page>
  );
};
