import { FC } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from '../../components/Link/Link';
import { Lottie } from '../../components/Lottie/Lottie';
import { Page } from '../../components/Page/Page';
import { ProjectList } from '../../components/ProjectList/ProjectList';
import { Section } from '../../components/Section/Section';
import { Spacer } from '../../components/Spacer/Spacer';
import { Text } from '../../components/Text/Text';
import { getFeaturedProjects } from '../../constants/projects';
import { Project } from '../../types/Project';
import styles from './Article.module.scss';

interface ArticleProps {
  project: Project;
}

export const Article: FC<ArticleProps> = ({ project }) => {
  const featuredProjects = getFeaturedProjects(project.featuredProjects);
  console.log(featuredProjects);

  return (
    <Page>
      <div className={styles.articleContainer}>
        <div className={styles.articleBackground} style={{ backgroundColor: project.color }} />
        <Container>
          <Spacer size="lg" />
          <Section>
            <Link iconLeft animated back>
              Zurück
            </Link>
            <Spacer size="lg" />
            <Text className={styles.categoryText} color="blue" bold>
              {project.category.toUpperCase()}
            </Text>
            <Spacer size="sm" />
            <Text variant="hero">{project.title}</Text>
            <Spacer size="md" />
            <img className={styles.image} src={project.image} alt={project.title} />
            <Spacer size="xl" />
            {project.description.map((text, index) => (
              <Text key={text + index} variant="paragraph" color={project.descriptionColor}>
                {text}
              </Text>
            ))}
            {project.externalUrl && (
              <>
                <Spacer size="xs" />
                <Link
                  to={project.externalUrl}
                  color={project.descriptionColor}
                  lottieFile={project.externalLinkLottie}
                  iconRight
                  animated
                >
                  Website
                </Link>
              </>
            )}
          </Section>
          {project.sections.map((section, index) => (
            <div key={section.title + index}>
              <Spacer size="xl" />
              <Section title={section.title} titleColor={section.titleColor}>
                {section.image && (
                  <img
                    className={styles.image}
                    style={{ width: section.imageWidth || '100%' }}
                    src={section.image}
                    alt={section.title}
                  />
                )}
                {section.lottie && (
                  <Lottie
                    className={styles.image}
                    style={{ width: section.imageWidth || '100%' }}
                    file={section.lottie}
                    play
                    loop
                  />
                )}
                {index === project.sections.length - 1 && <Spacer size="xxl" />}
              </Section>
            </div>
          ))}
        </Container>
      </div>
      <Container>
        <Spacer size="xxl" />
        <Section title="Projekte">
          <ProjectList projects={featuredProjects} />
        </Section>
        <Spacer size="xxl" />
      </Container>
    </Page>
  );
};
