import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../components/ProtectedRoute/ProtectedRoute';
import { projects } from '../constants/projects';
import { Page404 } from '../pages/404/404';
import { Article } from '../pages/article/Article';
import { Home } from '../pages/home/Home';
import { Login } from '../pages/login/Login';
import { useStore } from '../store/useStore';

export const App = () => {
  const { dispatch } = useStore();

  useEffect(() => {
    const onUnload = () => {
      dispatch({ type: 'CLEAR' });
    };

    window.addEventListener('unload', onUnload);
    return () => window.removeEventListener('unload', onUnload);
  }, [dispatch]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      {projects.map((project, index) => (
        <Route
          key={project.title + index}
          path={project.url}
          element={
            <ProtectedRoute>
              <Article project={project} />
            </ProtectedRoute>
          }
        />
      ))}
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
